import { Route, Router, Switch } from "react-router-dom";

import DataCollection from "../pages/DataCollection"
import ErrorPage from "../pages/Error"
/* eslint-disable import/no-duplicates */
import React from "react";
// import Login from "../pages/Login";
import history from '../history';

const App = () => {
  return (
    <div>
    <Router history={history}>
      <Switch>
        <Route exact path="/error/">
          <ErrorPage />
        </Route>
        <Route exact path="/:projectName">
          <DataCollection />
        </Route>
        <Route exact path="/data_collection">
          <DataCollection />
        </Route>
      </Switch>
    </Router>
    </div>
  );
}
export default App;
