import React from "react";

import { useLocation } from "react-router-dom";

const ErrorPage = () => {
  // const classes = useStyles();
  // const history = useHistory()
  const location = useLocation();

    return (
      <div>
        <p style={{ marginTop: "100px", textAlign: "center" }}>{location?.state?.message ? location.state.message : 'Error'}</p>
      </div>
    );
  }

export default ErrorPage;
