import AppBar from '@material-ui/core/AppBar';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../styles/images/icons/logo.png"
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
      zoom: '50%'
  },
  flex: {
      display: "flex"
  },
  equal: {
      flex: "1"
  },
}));

export default function MenuAppBar () {
  const classes = useStyles();
  const projectName = useSelector(state => state.globalActionsReducer.projectMetaData?.name ? state.globalActionsReducer.projectMetaData.name : "")

  return (
    <div className={classes.root}>
      <AppBar position="static">
          <Toolbar className={classes.flex}>
            <div className={classes.equal}>
              <img src={logo} alt="logo" className={classes.logo}/>
            </div>
            <div style={{ color: '' }}>
                <h3>{projectName} Data Collection</h3>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
