import { useDispatch, useSelector } from 'react-redux'

import Appbar from "../components/Appbar"
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import ProgressBar from "../components/progressBar"
import React from "react";
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import { getProjectMetaDataAPI } from "../env";
import { getProjectMetaData } from "../actions/global"
import makeStyles from "@material-ui/core/styles/makeStyles";
import qs from 'query-string'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { readFile } from 'xlsx';

const useStyles = makeStyles((theme) => ({
    root: {
      height: '100vh',
    },
    paper: {
      // margin: theme.spacing(15, 3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      verticalAlign: 'center',
    },
    paper2: {
      position: 'absolute',
      width: "30%",
      minWidth: '300px',
      height: "500px",
    },
    description: {
      margin: '20px',
      padding: '20px',
      width: "30%",
      marginLeft: "34%",
    },
    main: {
        marginTop: "5%"
    }
  }));

const DataCollection = () => {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()

    const [fileToUpload, setFileToUpload] = React.useState()
    const fileInput = React.createRef()
    const [fileName, setFileName] = React.useState("")
    const [uploadURL, setUploadURL] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [uploading, setUploading] = React.useState(false)
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const { projectName } = useParams()

    const slackWebhookUrl = 'https://hooks.slack.com/services/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';
    // const slackWebhookUrl_dev = 'https://hooks.slack.com/services/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'
    
    function sendSlackNotification(file) {
      const exceptionBody = {};
      const timestamp = new Date().toLocaleString('en-GB', { timeZone: 'Asia/Kolkata' }).replace(',', '') + ' +05:30  ';
      exceptionBody["Timestamp"] = timestamp;
      exceptionBody["Message"] = "Error occured while Uploading File  ";
      exceptionBody["FileName"] = file.name;
    
      const slackMessage = {
        text: JSON.stringify(exceptionBody)
      };
    
      fetch(slackWebhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(slackMessage),
      })
      .then(response => response.json())
      .then(data => console.log('Slack notification sent:', data))
      .catch(error => console.error('Error sending Slack notification:', error));
    }
    

  async function uploadFileInChunks (file, sessionUri) {
    const chunkSize = 1024 * 1024 * 10; // 10MB chunks

    let start = 0;
    const fileSize = file.size
    try{
      while (start < fileSize) {
        const end = Math.min(start + chunkSize, fileSize);
        const chunk = await file.slice(start, end);
        const contentRange = `bytes ${start}-${end - 1}/${fileSize}`;

        const response = await fetch(sessionUri, {
          method: 'PUT',
          body: chunk,
          headers: {
            'Content-Range': contentRange,
            'Content-Type': "application/octet-stream;",
          },
        });

        if (response.ok || response.status === 308) {
            setUploadProgress((start / fileSize) * 100)
        } else {
          throw new Error("Error uploading part")
        }
        start = end;
      }
  }
  catch (err) {
    console.log("Error uploading part")
    sendSlackNotification(file);

  }

    setMessage("Successfully uploaded " + fileName + " !!")
    setFileName("")
    setUploadURL("")
    setUploading(false)
    setUploadProgress(0)
    send_email()
    }
    const getFileMetaData = async (fileToUpload) => {

      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (fileExtension === 'xlsx' || fileExtension === 'xls') {

        const fileData = await new Response(fileToUpload).arrayBuffer();
        const workbook = readFile(fileData, { type: 'array' });
        const sheetName = workbook.SheetNames[0];

        const worksheet = workbook.Sheets[sheetName];
        const rowCount = worksheet['!ref'].split(':').pop().match(/[0-9]+/)[0];

        return ["excel", `containing ${rowCount} rows`];
      }

      return ['','']
    }

      const send_email = async () => {
    

      const [file_type,description] = await getFileMetaData(fileToUpload)
      
      const baseURL = getProjectMetaDataAPI + '/send_email'  
      const body = { file_type:file_type, description: description }
      const params = { project_name: projectName, filename: fileName }
      const url = qs.stringifyUrl({ url: baseURL,query: params })

      fetch(url,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body), 
      })
      .then(response => response.json())

    }
    const getUploadURL = (fileName) => {
        // eslint-disable-next-line camelcase
        const baseURL = getProjectMetaDataAPI + '/upload_file'
        const params = { project_name: projectName, filename: fileName }
        const url = qs.stringifyUrl({ url: baseURL, query: params })
        fetch(url)
        .then(response => response.json())
        .then(contents => contents.signed_url ? setUploadURL(contents.signed_url) : history.push({ pathname: '/error/', state: { message: 'Failed to get upload access.' } }))
        .catch(() => { console.log("Can’t access response. Blocked by browser?"); history.push({ pathname: '/error/', state: { message: 'Blocked by browser.' } }) })
    }

    /*
        uploadFile:
            This function adds the binary file in formdata and pass it over the given url.
            This function also updates the upload progress
    */
    const uploadFile = (url, file) => {
        axios.request({
            method: "PUT",
            url: url,
            headers: {
                'Content-Type': 'application/octet-stream;'
              },
            data: file.files[0],
            onUploadProgress: (p) => {
              setUploadProgress(Math.round((p.loaded * 100) / p.total))
            }
          }).then(response => {
              if (response.request.status === 200) {
                setMessage("Successfully uploaded " + fileName + " !!")
                setFileName("")
                setUploadURL("")
                setUploading(false)
                setUploadProgress(0)
              }
          }).catch(err => {
            console.log(err)
            history.push({ pathname: '/error/', state: { message: 'Failed to upload <br/> ' + err } })
          })
    }
    const handleSubmit = (event) => {
      event.preventDefault();
      if (fileName) {
          setUploading(true)
          getUploadURL(fileName)
        }
    }
    React.useEffect(() => {
        if (uploadURL) {
          // uploadFile(uploadURL, fileInput.current)
          uploadFileInChunks(fileToUpload, uploadURL)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadURL])

    /*
        This function is used to update selected file name on UI
    */
    const newFile = () => {
        setFileName(fileInput.current.files[0].name)
        setFileToUpload(fileInput.current.files[0])
        setMessage("")
    }
    return (
      <div>
        <Appbar />
        <div className={classes.main}>
        {/* {projectMetaData?.description ? <Paper elevation={4} className={classes.description}>{projectMetaData.description} </Paper> : <></>} */}
        <div className={classes.paper} >
        <Paper className={classes.paper2} elevation={4}>
            <div style={{ paddingTop: "10%", paddingLeft: "20%" }}>

                <Typography component="h1" variant="h5" style={{ fontSize: "24px", lineHeight: "1.33" }}>
                    <Box fontWeight={600} m={1}>
                    Upload Data
                    </Box>
                </Typography>
                <Typography variant="caption" display="block" gutterBottom style={{ color: "gray", fontSize: "14px", letterSpacing: "normal" }}>
                    1. Click on Select File Button<br/>
                    2. Select the file from your computer<br/>
                    3. Click on Upload File Button
                </Typography>

                <form onSubmit={event => handleSubmit(event)} style={{ marginTop: '50px' }}>
                    <Button disabled={uploading} variant="outlined" component="label" color="secondary" >Select File<input onChange={() => newFile()} type="file" ref={fileInput} hidden /></Button>
                    <Button disabled={uploading || !fileName} variant="contained" color="secondary" type="submit" style={{ marginLeft: '10px' }}>Upload File</Button>
                </form>

                <br /><br />{fileName}<br /><br />

                {uploading ? <div><Typography color="secondary">Uploading...</Typography><ProgressBar value={uploadProgress}/> </div> : <></> }

                <span style={{ color: 'blue' }}>{message}</span>
            </div>
        </Paper>
        </div>
        </div>
      </div>
    )
}

export default DataCollection;
