import { SET_PROJECT_METADATA } from "../actions/types";

const initialState = {
  projectMetaData: {}

};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_PROJECT_METADATA:
      return Object.assign({}, state, {
        projectMetaData: action.data
      });
    default:
      return state;
  }
};
